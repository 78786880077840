import React, {useState} from "react";
import "../../assets/scss/styles.scss"

import DarkFooter from "../../components/Footers/DarkFooter.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import acin from '../../assets/img/logos/ACIN.jpg'
import aristotle from '../../assets/img/logos/aristotle.png'
import tu from '../../assets/img/logos/TULogo.jpg'
import fraport from '../../assets/img/logos/fraport1.png'
import masoutis from '../../assets/img/logos/logoMasoutis_white.jpg'
import sdi from '../../assets/img/logos/sdi_logo_primary_cmyk_01.jpg'
import ssa from '../../assets/img/logos/SSSA_logo.png'
import Ciop from '../../assets/img/logos/CIOP_PIB.png'
import burgos from '../../assets/img/logos/UoBurgos logo.png'
import ABB from '../../assets/img/logos/ABB_Logo_Screen_RGB_33px_@2x.png'
import bristol from '../../assets/img/logos/university-of-bristol-logo-png-transparent.png'
import iti from '../../assets/img/logos/ITI_logo_EN.jpg'
import twi from '../../assets/img/logos/THL_logo.png'
import certh from '../../assets/img/logos/CERTH_Logo.png'
import tuda from '../../assets/img/logos/tuda_logo.png'






import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import Modal from 'react-bootstrap/Modal';


function Partners() {
  
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        
 
        <section className="section section-about-us" id='partners' style={{backgroundColor:'#ffffff'}}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#2f5597',marginTop:'50px'}}>Consortium</h2>
                <section class="section section-default mt-none mb-none" id='partnerz' style={{backgroundColor:'#ffffff'}}>
<div class="container" style={{padding:'0px'}}>

<div id='certh' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#2f5597'}}><img src={certh} style={{width:'140px'}}/>   
 <span> <a target="_blank" href="https://www.certh.gr/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#5968a5',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer'}}/></a></span>
 <div id='iti'><img src={iti} style={{width:'140px'}}/>
 <span> <a target="_blank" href="https://www.iti.gr/iti/en/home/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#5968a5',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer'}}/></a></span>
</div>
 </h4> 
<hr id='grammi' />   
<p style={{color:'#2f5597',textAlign:'justify'}}>The Centre for Research and Technology Hellas was fοunded in March 2000, it is a legal
entity governed by private law with non-profit status, supervised by the General Secretariat
for Research and Innovation (GSRI) of the Greek Ministry of Development. CERTH suggests
one of the most important Research Centers in Greece with a main mission: The promotion
of innovative research for the benefit of society. Dedicated to this purpose, CERTH lies at
the forefront of basic, applied and technological research to provide solutions to society’s
modern challenges.
</p>
 <p style={{color:'#2f5597',textAlign:'justify'}}>
 <b>The Information Technologies Institute</b> (ITI) was founded in 1998 as a non-profit organization
under the auspices of the General Secretarial of Research and Technology of Greece, with
its head office located in Thessaloniki, Greece. Since 10.3.2000 it is a founding member of
the <b>Centre of Research and Technology Hellas</b> (CERTH), also supervised by the Greek
Secretariat of Research and Technology. ITI is one of the leading Institutions of Greece in
the fields of Informatics, Telematics and Telecommunications.
 </p>
<p style={{color:'#2f5597',textAlign:'justify'}}>
Since 1998 ITI has participated in a great number of Research and Development projects
funded by European, Public investment funds and Services contracted by firms and other
private legal entities. ITI’s research teams show their remarkable scientific work through a
number of publications that includes scientific publications in international journals,
publications in conferences, books and book chapters. ITI was evaluated by external Expert
Committees in the area of Engineering, in 2002, 2005 and 2014 and received a grade of
9.65/10, 4.4/5 and 4.51/5, respectively.
</p>
<p style={{color:'#2f5597',textAlign:'justify'}}>
The Institute has the infrastructure, the experience and the maturity to intensify its efforts on
diffusing its research activities to key stakeholders and using all the necessary mechanisms
and tools towards efficiently bringing novel or improved services and products to them
through the Institute’s established spin offs. Specifically same actions have been made in
the recent years through ITI’s collaborations with numerous Institutions in Greece and
abroad as well as the founding of 7 new spin-offs.</p>

   
<hr id='grammi'/>
</div>

  <div id='tu' style={{textAlign:'left'}}>
<br/>

<h4 style={{fontWeight:600, color:'#2f5597'}}><img src={tu} style={{width:'140px'}}/>     <span> <a target="_blank" href="https://www.acin.tuwien.ac.at/en/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#5968a5',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer'}}/></a></span></h4>    
<hr id='grammi'/>
<p style={{color:'#2f5597',textAlign:'justify'}}>The <b>TU Wien</b> is Austria's largest research and educational institution in the field of technology and natural sciences. More than 4,000 scientists are researching "technology for people" in five main research areas at eight faculties. 
The content of the studies offered is derived from the excellent research. More than 26,000 students in 62 degree programmes benefit from this. As a driver of innovation, TU Wien strengthens the business location, facilitates cooperation and contributes to the
 prosperity of society.</p>
 <p style={{color:'#2f5597',textAlign:'justify'}}>
The Automation and Control Institute (ACIN) belongs to the Faculty of Electrical Engineering and Information Technology, TU Wien. With more than 70 employees, our institute does basic research, solves challenging practical problems in a number of cooperative projects with industry, develops innovations, and gives the students a profound research-oriented teaching in systems theory, automation and control. The institute is divided into the research areas advanced mechatronic systems and complex dynamical systems.
</p>
<hr id='grammi'/>
   <br/>
</div>

<div id='fraport'style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#2f5597'}}><img src={fraport} style={{width:'140px'}}/>   <span> <a target="_blank" href="https://www.fraport-greece.com/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#5968a5',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer'}}/></a></span></h4> 
<hr id='grammi' style={{color:'#5968a5'}}/>   
<p style={{color:'#2f5597',textAlign:'justify'}}><b>Fraport Greece</b>  is a leading airport operator and management company with a strong presence in Greece. Established in 2017, the company is a joint venture of Fraport AG Frankfurt Airport Services Worldwide, Copelouzos Group and Marguerite 
  (the 2020 European Fund for Energy, Climate Change and Infrastructure). Its primary focus is the management, operations and development of the 14 regional airports on the Greek mainland and islands. Fraport Greece’s commitment is the enrichment and enhancement 
  of the passenger experience by modernizing and upgrading airport infrastructure, services and facilities, in line with international standards. Through investments in infrastructure development, environmental sustainability and state-of-the-art technology, 
  Fraport Greece strives to drive economic growth in the regions it serves while promoting tourism and local development. With a strong focus on safety, efficiency and sustainability, Fraport Greece plays a pivotal role in Greece's aviation industry and contributes
   to the nation's tourism and economic development.</p>
   
<hr id='grammi'/>
</div>

<div id='twi' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#2f5597'}}><img src={twi} style={{width:'140px'}}/>   <span> <a target="_blank" href="https://www.twi-hellas.com/ "><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#5968a5',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer'}}/></a></span></h4> 
<hr id='grammi' style={{color:'#5968a5'}}/>   
<p style={{color:'#2f5597',textAlign:'justify'}}><b>TECH HIVE LABS (THL)</b> i is a European centre of excellence in Robotics and AI, where bold ideas and applied research turn into industrial solutions with real impact. We blend the curiosity of a lab with the precision of a business leader to drive technology engineering and transform experimentation into real-world capability, empowering entrepreneurs and enterprises to innovate with confidence and deliver measurable results. 
</p>
  <p style={{color:'#2f5597',textAlign:'justify'}}>Our motto is <b>"Serious Playground. Real Impact."</b>, which is also our way of working. We offer diverse engagement models, from collaborative projects and knowledge exchange to engineering consulting and technology transfer, to suit the specific needs of research collaborators and industry partners and ensure that every endeavour drives real results.
  </p>

<hr id='grammi'/>
</div>


<div id='bristol' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#2f5597'}}><img src={bristol} style={{width:'140px'}}/>   <span> <a target="_blank" href="https://www.bristol.ac.uk/engineering/ "><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#5968a5',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer'}}/></a></span></h4> 
<hr id='grammi' style={{color:'#5968a5'}}/>   
<p style={{color:'#2f5597',textAlign:'justify'}}>The <b>University of Bristol</b>  is at the cutting edge of global research. We have made innovations in areas ranging from cot death prevention to nanotechnology. The University has had a reputation for innovation since its founding in 1876. Our research tackles some of the world’s most pressing issues in areas as diverse as infection and immunity, robotics and automation, climate change, and cryptography and information security. Bristol is one of the most popular and successful universities in the UK, ranked 55th in the world in the QS World University Rankings 2024.

Bristol Robotics Laboratory (BRL) is the most comprehensive academic centre for multi-disciplinary robotics research in the UK. It is home to a vibrant community of over 450 academics, researchers and industry practitioners. Together, they are world leaders in current thinking on service robotics, intelligent autonomous systems and bio-engineering. An internationally recognised Centre of Excellence in Robotics, BRL’s state-of-the-art facilities cover an area of over 4,600 sq. metres (50,000 sq. feet). The Laboratory is currently involved in interdisciplinary research projects addressing key areas of robot capabilities and applications including: smart automation, human-robot interaction, bio-energy and self-sustainable systems, biomimetic tactile sensors and haptic feedback systems, unmanned aerial vehicles, swarming behaviour, assisted living technologies, non-linear control, medical and rehabilitation robotics, robot safety and soft robotics.
</p>
<hr id='grammi'/>
</div>


<div id='ssa' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#2f5597'}}><img src={ssa} style={{width:'140px'}}/>   <span> <a target="_blank" href="https://www.santannapisa.it/en/institute/biorobotics"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#5968a5',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer'}}/></a></span></h4> 
<hr id='grammi' style={{color:'#5968a5'}}/>   
<p style={{color:'#2f5597',textAlign:'justify'}}>Advanced education, frontier research and innovation: this is <b>The BioRobotics Institute of Sant'Anna School of Advanced Studies,</b> founded in 2011. With time the Institute has built a wealth of knowledge and expertise in several fields of biorobotics and bionics, such as medical robotics, wearable technologies, collaborative robotics, bioinspired robotics, neuroscience robotics, rehabilitation robotics and implantable technologies.
The Institute promotes the internationalization of didactics and scientific research through collaboration with the most prestigious international knowledge centers. </p>
<p style={{color:'#2f5597',textAlign:'justify'}}>
At The BioRobotics Institute we exploit new paradigms of collaborative and wearable technologies within a wide spectrum of application scenarios, such as in designing new collaborative robots for medical/industrial applications, or in designing novel devices and sensing technologies to improve workplaces ergonomics by reducing the burden of workers in strenuous repetitive tasks.
</p>
<p style={{color:'#2f5597',textAlign:'justify'}}>In our labs, we design, develop and validate novel devices for assisting, rehabilitating, or augmenting human motor functions, new industrial and medical robots for collaborating fluently with humans, tactile sensors to facilitate safe human-machine co-work, robots for assistance, healthcare, agriculture, logistics, and manufacturing.</p>
<hr id='grammi'/>
</div>


<div id='ciop' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#2f5597'}}><img src={Ciop} style={{width:'140px'}}/>   <span> <a target="_blank" href="https://www.ciop.pl/CIOPPortalWAR/appmanager/ciop/en?_nfpb=true&_pageLabel=P58400150771635445532884"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#5968a5',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer'}}/></a></span></h4> 
<hr id='grammi' style={{color:'#5968a5'}}/>   
<p style={{color:'#2f5597',textAlign:'justify'}}><b>The Central Institute for Labour Protection – National Research Institute (CIOP-PIB)</b> is a leading research institution in the field of occupational safety and health (OSH) in Poland. The institute brings together extensive knowledge and experience in conducting scientific research aimed at developing new technological and organizational solutions useful in designing working conditions compliant with occupational safety and ergonomics requirements and determining scientific foundations for the development of socio-economic policies in OSH. CIOP-PIB’s main activities focus on: research and development in the field of OSH, determination of exposure limits; standardization, testing and certification (machinery, manufacturing devices, personal and collective protective equipment), education and training, and dissemination of OSH solutions through publications and websites. Moreover, CIOP-PIB experts are competent in the field of EU legal requirements concerning OSH. The Institute implements the National Programme for the improvement of safety and working conditions. Since 2008, the programme has fulfilled the role of a national strategy for creating proper working conditions that protect the life and health of workers. The Institute has also a vast experience in national and international projects, including those funded under Horizon Europe and Horizon 2020 programme</p>
<hr id='grammi'/>
</div>


<div id='masoutis' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#2f5597'}}><img src={masoutis} style={{width:'140px'}}/>   <span> <a target="_blank" href="https://www.masoutis.gr/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#5968a5',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer'}}/></a></span></h4> 
<hr id='grammi' style={{color:'#5968a5'}}/>   
<p style={{color:'#2f5597',textAlign:'justify'}}><b>Diamantis Masoutis SA</b>, founded in 1976 in Thessaloniki, is a purely Greek retail firm that maintains its family character unchanged to this day. The company holds a prominent market position in the market in Northern Greece and stands among the pioneering entities in the supermarket sector. The company's retail network comprises a total of 389 stores, including 349 neighborhood stores, which serve as the company's primary development model, 15 GRAND Masoutis hypermarkets, 1 green store, and 23 Cash & Carry stores focusing on wholesale operations.
</p>
<p style={{color:'#2f5597',textAlign:'justify'}}>
The company is making significant investments in technology to facilitate its digital transformation and is also focusing on infrastructure development.  It has one of the largest and most advanced Logistics Centers in the Balkans, covering an expansive area of 145,000 sq.m. and offering high-quality storage, distribution, and quality assurance services.
</p>
<p style={{color:'#2f5597',textAlign:'justify'}}>It has a workforce exceeding 10,000 employees and for 11 consecutive years has been ranked among the top 10 companies with the best working environment in Greece!</p>
<p style={{color:'#2f5597',textAlign:'justify'}}>In 2009, the company proceeded to build the first "green" store with bioclimatic architecture, and the use of recyclable materials. The store has photovoltaic systems, and the trolleys and baskets are made from recycled plastic bottles. Heating and cooling are achieved using geothermal techniques.</p>
<p style={{color:'#2f5597',textAlign:'justify'}}>In 2022, the store located in the Blind’s School neighborhood at Thessaloniki undergone modifications to enhance accessibility for people with visual impairments and disabilities. In this supermarket, visually impaired people have autonomy both during their browsing in the store and during the process of choosing products from the shelf.</p>
<hr id='grammi'/>
</div>


<div id='abb' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#2f5597'}}><img src={ABB} style={{width:'140px'}}/>   <span> <a target="_blank" href="https://new.abb.com/es "><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#5968a5',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer'}}/></a></span></h4> 
<hr id='grammi' style={{color:'#5968a5'}}/>   
<p style={{color:'#2f5597',textAlign:'justify'}}><b>Asea Brown Boveri SA (ABB)</b> in Spain is a leader and technological pioneer which collaborates with clients of service companies, industries, transport media and infrastructures in order to write the future of industrial digitalization together. It has a complete portfolio of industrial technology products for clients of public services, industries, transports and infrastructures. It is part of the ABB group which is a global leader in industrial technology. Based in Zurich, Switzerland, the company employs around 105,000 people and operates in approximately 100 countries. ABB’s success has been driven particularly by a strong focus on research and development. 
</p>

<hr id='grammi'/>
</div>

<div id='aristotle' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#2f5597'}}><img src={aristotle} style={{width:'140px'}}/>   <span> <a target="_blank" href="https://arl.ee.auth.gr/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#5968a5',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer'}}/></a></span></h4> 
<hr id='grammi' style={{color:'#5968a5'}}/>   
<p style={{color:'#2f5597',textAlign:'justify'}}><b>Aristotle University of Thessaloniki</b> is the largest university in Greece. The main campus is located in the centre of the city of Thessaloniki, and covers an area of about 33.4 hectares. 
It comprises 10 faculties which consist of 40 schools and 1 single-School Faculty.</p>
<p style={{color:'#2f5597',textAlign:'justify'}}>
The Automation and Robotics Laboratory (ARL), https://arl.ee.auth.gr/, within the School of Electrical and Computer Engineering (ECE) of Aristotle University of Thessaloniki (AUTh) has a long standing research record in the areas of robotics and control systems. In the field of robotics the lab has an expertise in the area of physical human-robot interaction, robot teaching and learning by demonstration, bimanual mobile robots, object grasping and manipulation with analytical and data based learning methods and the control of uncertain robotic systems.  In the automatic control field ARL is specializing in the control of complex and uncertain systems with prescribed performance guarantees. ARL is also active in the field of computational intelligence.  
ARL is equipped with state-of-the-art collaborative robotic lightweight manipulators, an omnidirectional robotic platform and robotic hands and sensors. The ARL research group has participated in over 20 research projects funded or co-funded by EU.</p>
<hr id='grammi'/>
</div>

<div id='tuda' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#2f5597'}}><img src={tuda} style={{width:'140px'}}/>   <span> <a target="_blank" href="https://www.tu-darmstadt.de/universitaet/index.en.jsp"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#5968a5',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer'}}/></a></span></h4> 
<hr id='grammi' style={{color:'#5968a5'}}/>   
<p style={{color:'#2f5597',textAlign:'justify'}}><b>The Technical University of Darmstadt</b> (TU Darmstadt) is a synonym for excellent, relevant science. Since it was founded in 1877, it has been one of Germany’s most international universities; as a European technical university, it is committed to European values and European integration. TU Darmstadt's home is the metropolitan region Frankfurt-Rhine-Main. With partners in the Alliance of Rhine-Main Universities, TU Darmstadt continues the development of this globally attractive science location.
</p>
<p style={{color:'#2f5597',textAlign:'justify'}}>
The Computer Science (CS) department of TU Darmstadt was founded in 1972, as one of the first CS departments in Germany. Today, it covers a wide range of topics in basic and applied research as well as teaching. Their research, focused on artificial intelligence, cybersecurity, networked systems, etc. shapes the profile of TU Darmstadt in the research field “Information + Intelligence”. The researchers and graduates of the department advance the digitalized world and make an important contribution to a safe and livable future</p>
<p style={{color:'#2f5597',textAlign:'justify'}}>The Interactive Robot Perception & Learning (PEARL) Lab at TU Darmstadt, headed by Prof. Georgia Chalvatzaki, was founded in April 2023. The lab's mission is to enable embodied AI agents to perform long-horizon tasks in home environments while seamlessly interacting with humans. The vision of PEARL entails that the generalization power of machine learning methods is necessary to enhance classical model-based approaches in robotics, e.g., path planning, and to endow robots with intelligent behavior.</p>
<hr id='grammi'/>
</div>

<div id='sdi' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#2f5597'}}><img src={sdi} style={{width:'140px'}}/>   <span> <a target="_blank" href="https://digital.schwarz/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#5968a5',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer'}}/></a></span></h4> 
<hr id='grammi' style={{color:'#5968a5'}}/>   
<p style={{color:'#2f5597',textAlign:'justify'}}>Schwarz Digits is the IT and digital division of Schwarz Group. It offers compelling digital products and services that meet Germany's high data protection standards. Schwarz Digits thus guarantees the greatest possible digital sovereignty. With this claim, Schwarz Digits provides the IT infrastructure and solutions for the extensive ecosystem of the companies of Schwarz Group and develops it further for the future. Schwarz Digits creates optimal conditions for the development of trend-setting innovations for end customers, companies and public sector organizations. Schwarz Digits includes 7,500 employees of the brands Schwarz IT, <b>Schwarz Digital</b>, STACKIT, XM Cyber, Kaufland e-commerce, Lidl e-commerce, Schwarz Media and mmmake.
</p>
<hr id='grammi'/>
</div>

<div id='burgos' style={{textAlign:'left'}}>

<br/>

<h4 style={{fontWeight:600, color:'#2f5597'}}><img src={burgos} style={{width:'140px'}}/>   <span> <a target="_blank" href="https://www.ubu.es/"><FontAwesomeIcon id='globe' variant="secondary" icon={faGlobe} style={{color:'#5968a5',fontSize:'20px',marginTop:'8px', marginLeft:'20px',cursor:'pointer'}}/></a></span></h4> 
<hr id='grammi' style={{color:'#5968a5'}}/>   
<p style={{color:'#2f5597',textAlign:'justify'}}><b>The University of Burgos (UBU)</b> is a public university, recognised among the best young universities in the world, which develops its mission based on the provision of comprehensive and quality teaching, very close to the student and focused on internationalisation. Founded in 1994, the UBU is already a benchmark in Spanish university research and knowledge transfer to the business world.</p>
<p style={{color:'#2f5597',textAlign:'justify'}}>It has a highly qualified teaching staff committed to research, knowledge transfer and the use of new technologies applied to teaching. It currently offers 26 bachelor’s degrees, 5 of which are taught online and face-to-face and 2 are bilingual in English and Spanish, in addition to 7 double degrees, 27 master’s degrees, 13 doctoral programmes and 16 UB-specific degrees; with over 7,500 students at 7 centres. Among its strategic objectives are internationalization and quality in education, research and knowledge transfer. UBU has 87 research groups in 5 main research fields (Sciences, Social sciences, Engineering-Construction, Humanities & Health sciences) with consolidated prestige in research and scientific publications.
</p>
<hr id='grammi'/>
</div>


</div>
</section>
              </Col>
            </Row>
            </Container>
            </section>
           
       
        <DarkFooter />
       
      </div>
    </>
  );
}

export default Partners;
