import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';
import {Helmet} from "react-helmet";
import image1 from '../views/pages/Picture1.jpg'
import image2 from '../views/pages/Picture2.png'


const Blog2 = () => {



  return (
    <>   
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  MANiBOT in Motion: How ABB's Visual SLAM Technology is Transforming Robot Navigation

                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
            10/01/2025
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                
    
                <p style={{textAlign:'justify'}}>
                Mobile robots are becoming more common in places where humans work and move around, like warehouses, stores, and airports. For these robots to work well, they need to be able to move safely, precisely, and adapt to changes in their environment. The traditional robot navigation technologies – using laser sensors, magnetic strips on the floor, or special QR code markers – were developed for industrial, well-structured, environments. Thus, these older methods often require a lot of additional set up and they suffer decreased efficiency in frequently changing environments with a lot of moving objects. This challenge is particularly clear in the MANiBOT project, which aims to develop mobile robots with two robotic arms to perform complex tasks in real-world settings. Addressing this, ABB Robotics is contributing to this project by introducing an advanced vision-based navigation system that uses artificial intelligence to overcome the navigation challenges faced by MANiBOT and similar advanced robotic systems.
              </p> 
              <p style={{textAlign:'justify'}}>This navigation system is based on Visual SLAM (Visual Simultaneous Localization and Mapping) technology, which uses cameras to create a three-dimensional map of the space around the robot. Unlike older methods that only create flat, two-dimensional maps using laser sensors (2D-LiDAR-based systems), Visual SLAM builds a richer understanding of the environment. Like humans, Visual SLAM uses features existing naturally in the space – from marks on the floor to objects on the ceiling – to help the robot know exactly where it is. This works especially well in areas that do not have special navigation markers and in places where things move around frequently.      </p>
              <p style={{textAlign:'justify'}}>The advantages of Visual SLAM become clear when you look at how well it performs. In complex environments, Visual SLAM-based systems can be up to 95% accurate, while traditional laser-based systems typically achieve only 60-70% of accuracy (Sevensense Robotics, 2023). This improved precision comes from Visual SLAM's ability to differentiate between fixed and moving objects, understand what is changing in the surrounding space, and always update its map to guarantee high accuracy over time.              </p>
              <div class="outer"> <figure class="img1 embed news"> <img
                  src={image1}
                  style={{borderRadius:'10px',width:'400px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <figcaption style={{width:'620px', fontSize:'13px',marginTop:'10px'}}>Figure 1. VSLAM Vs Traditional 2D LiDAR-based systems</figcaption>
                </figure></div><br/>
              
              <p style={{textAlign:'justify'}}>Visual SLAM advantages extend beyond just helping robots find their way around. The same cameras used for navigation can also help robots detect objects and avoid obstacles. This is especially important in places like supermarkets, airports, and warehouses, where robots need to detect and respond quickly to unexpected obstacles to ensure safety and efficiency. Visual SLAM also helps robots recognize and identify different objects in their environment, which makes them better at planning and carrying out their tasks.
              </p>  
              <div class="outer"> <figure class="img1 embed news"> <img
                  src={image2}
                  style={{borderRadius:'10px'}}
                  alt="about us"
                  className="img-fluid"
                />
                <figcaption style={{width:'620px', fontSize:'13px',marginTop:'10px'}}>Figure 2. Key Features of ABB VSLAM Solution</figcaption>
                </figure></div>
                <br/>
              <p style={{textAlign:'justify'}}>For the MANiBOT project, Visual SLAM's ability to create detailed 3D maps, understand the surrounding environment, and adapt to changing conditions is crucial. It helps ensure that these robots can work safely and efficiently alongside humans, even in unpredictable situations. 
                Through Visual SLAM, ABB Robotics and its partners are helping robots better handle the challenges of working in real-world environments, leading to exciting new possibilities in robotics. </p>     
                <p style={{textAlign:'justify'}}>Totally aligned with this mission, and based on its experience in leading roles in EU projects, CERTH is the coordinator of the MANiBOT project. Gathering a group of high-profile partners carefully selected for their acknowledged excellence on their fields, CERTH aims to promote scientific knowledge and provide innovative robotic technologies with a high impact on science, society and the economy.
              </p>
              <p style={{textAlign:'justify'}}> Want to know more?</p>
              <ul>
                <li><a href='https://new.abb.com/products/robotics/autonomous-mobile-robots/visual-slam'>https://new.abb.com/products/robotics/autonomous-mobile-robots/visual-slam</a></li>
                <li><a href='https://www.sevensense.ai/blog'>https://www.sevensense.ai/blog</a></li>
              </ul>
              <p style={{textAlign:'justify'}}> Abbas Sidaoui, <a href='https://new.abb.com/es'>Asea Brown Boveri SA</a> (ABB)</p>
             
              
              
              </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
    </>
  )
}

export default Blog2