import React from "react";
import "../../assets/scss/styles.scss"
import Img from 'react-cloudinary-lazy-image'
import Map from '../map.js'
import MyPDF1 from '../../assets/manibot-leaflet.pdf'
import MyPDF2 from '../../assets/manibot-poster.pdf'
import letter from './newsletter.png'
import { Link } from 'react-router-dom'


import DarkFooter from "../../components/Footers/DarkFooter.js";
import BlogList from "../../components/BlogList";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import poster from './Mesa de trabajo 1.png'
import leaflet from './222.png'
import image23 from './unnamed.png'
import image24 from './Picture1.jpg'
import image25 from '../../assets/img/Facilities.jpg'


// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import BlogList2 from "../../components/BlogList2";
import Zenodo from "./ZENODO";



function News() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <section className="section section-about-us" id='news'>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{color:'#163365',marginTop:'50px'}}>News</h2>
                <BlogList2/>
              </Col>
            </Row>
            </Container>
            </section> 
            <section className="section section-about-us" id='blog' style={{padding:'0px'}}>
        <div className="section section-team text-center" style={{backgroundColor:'#5072a4'}}>
        <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" style={{color:'#ededed'}}>Blog posts</h2>
              <div className="posts" >
		  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>
    
    <article class="postcard light blue">
			<a class="postcard__img_link" >
				<img class="postcard__img"  title="" src={image25} />
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a>How MANiBOT Innovations Will Transform Shelf Replenishment Workflow </a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						20/03/2025
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">In the fast-paced world of retail, efficient shelf replenishment will be crucial to maintaining customer satisfaction and ensuring the smooth operation of stores.
        </div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/How MANiBOT Innovations Will Transform Shelf Replenishment Workflow'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    
    <article class="postcard light blue">
			<a class="postcard__img_link" >
				<img class="postcard__img"  title="" src={image24} />
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a>MANiBOT in Motion: How ABB's Visual SLAM Technology is Transforming Robot Navigation </a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						10/01/2025
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Mobile robots are becoming more common in places where humans work and move around, like warehouses, stores, and airports.
        </div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/MANiBOT in Motion: How ABBs Visual SLAM Technology is Transforming Robot Navigation'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>


		<article class="postcard light blue">
			<a class="postcard__img_link" >
				<img class="postcard__img"  title="" src={image23} />
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a>The MANiBOT Project: its Goal and Importance </a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						10/11/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">In recent years, robotics has made significant progress in areas like perception, thinking, and movement. However, robots still demonstrate limited capabilities compared to that of humans especially when it comes to interacting with objects, humans and generally the real-world environment.
        </div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/The MANiBOT Project: its Goal and Importance'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
    </section></div>
              </Col>
            </Row>
            </Container>
        </div>

        </section>
            <section id='newsletter'>
        <div className="section section-team text-center" style={{backgroundColor:'#163365d9'}}>
        <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">  
              <h2 className="title" style={{color:'#ededed'}}>Newsletter</h2>
              <br/>
             <ul style={{padding:'0px'}}>
              <li style={{float:'left', color:'whitesmoke', listStyle:'none',marginBottom:'16px' }}><img src={letter} style={{width:'40px',marginRight:'10px',marginTop:'-20px'}}/>
              <p style={{float:'inline-end'}}><b>Newsletter Issue 1:</b> Read about our project and a summary of our latest news 
              <a style={{color:'#98b7dd'}}id='hyper' target="_blank" href='https://mailchi.mp/da25d5a91a2c/manibot-newsletter-no1'> here.</a></p></li> 
              <li style={{float:'left', color:'whitesmoke', listStyle:'none' }}><img src={letter} style={{width:'40px',marginRight:'10px',marginTop:'-20px'}}/>
              <p style={{float:'inline-end'}}><b>Newsletter Issue 2:</b> Discover our latest research and activities 
              <a style={{color:'#98b7dd'}}id='hyper' target="_blank" href='https://mailchi.mp/b66ae4f7290d/manibot-newsletter-no1-32618'> here.</a></p></li> 
             </ul>
             </Col>
            </Row>
            </Container>
        </div>
        </section>
       
            <section id='presskit'>
        <div className="section section-team text-center" style={{backgroundColor:'#5072a4'}}>
        <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" style={{color:'#ededed'}}>Press Kit</h2>
             <BlogList/>
              </Col>
            </Row>
            </Container>
        </div>
        </section>
        <section id='promotional_material'>
        <div className="section section-team text-center" style={{backgroundColor:'#ededed'}}>
        <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" style={{color:'#163365'}}>Promotional Material</h2>
              <div style={{float:'left'}}>
              <img style={{width:'230px',position:'relative',marginRight:'40px'}} src={leaflet}></img><br/>
              <a style={{color:'#2f5597',float:'left'}} id='newsletter' href={MyPDF1} download="Manibot Leaflet.pdf">Download MANiBOT Leaflet</a>
              </div> 
              <div style={{float:'left'}}>
              <img style={{width:'126px',position:'relative',float:'left'}} src={poster}></img><br/>
              <a style={{color:'#2f5597',width:'130px'}}id='newsletter' href={MyPDF2} download="Manibot Poster.pdf">Download MANiBOT Poster</a>
              </div> 

              </Col>
            </Row>
            </Container>
        </div>
        </section>
    
        <DarkFooter />
        
      </div>
    </>
  );
}

export default News;
