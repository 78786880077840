import React, { useEffect, useState } from "react";
import "../../assets/scss/styles.scss"
import Img from 'react-cloudinary-lazy-image'
import Map from '../map.js'
import icon from '../../assets/img/pdficon.svg'


import DarkFooter from "../../components/Footers/DarkFooter.js";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContactForm from "./contact";


// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";



function Zenodo() {
  const [publications, setPublications] = useState([]);

  useEffect(() => {
    fetch("https://zenodo.org/api/records/?q=MANiBOT")
      .then((response) => response.json())
      .then((data) => {setPublications(data.hits.hits)
        console.log(data.hits.hits)
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <>
    <ExamplesNavbar />
    <div className="wrapper">
      <section className="section section-about-us" id='zenodo' style={{padding:'0px'}}>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto " md="8">
            
    <div>
    <h4 className="title" style={{color:'#163365 '}}>Zenodo</h4>
      <ul style={{listStyle:'none',paddingLeft:'0px',textAlign:'left'}}>
        {publications.map((pub) => (
          <div style={{backgroundColor:'whitesmoke',borderRadius:'10px'}}>
          <li key={pub.id} style={{ marginBottom: "20px", padding: "10px"}}>
            {/* Τίτλος */}
           
              <a href={pub.links.doi} target="_blank" rel="noopener noreferrer">
          <h4 style={{fontSize:'17px',color:'#404040',fontWeight:'600'}}>        {pub.metadata.title}    </h4>
           
        

            {/* Συγγραφείς */}
            <p><strong>Authors:</strong> {pub.metadata.creators.map(creator => creator.name).join(", ")}</p>

            {/* Περιγραφή (Μικρό απόσπασμα) */}
            {pub.metadata.description ? (
              <p><strong>Description:</strong> {pub.metadata.description.slice(0, 200).replace("<p>","").replace("</p>","")}...</p>
            ) : (
              <p><strong>Description:</strong> No description available.</p>
            )}
      </a>     </li>  </div>
        ))}
      </ul>
    </div>  
            </Col>
          </Row>
          </Container>
          </section> 
          </div> 
          </>
  );
};

export default Zenodo;
