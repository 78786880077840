/*eslint-disable*/
import React from "react";
import logo from "../../assets/img/tubers_logo.svg"
// reactstrap components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faLinkedinIn, faXTwitter,faYoutube } from '@fortawesome/free-brands-svg-icons'
import { Container } from "reactstrap";
import eu from '../../assets/img/logos/eu.jpg'
import manibot from './manibot_final2.png'



function DarkFooter() {
  return (
    <footer className="footer">
    
    <div class="container py-5">
    <img src={manibot} style={{width:'160px',padding:'0px 5px'}}></img>
      <div class="row py-4">
        
        <div class="col-lg-4 col-md-6 mb-4 mb-lg-0">
       
        
        
        <h6 style={{color:'#dadada',textTransform:'none'}}>MANiBOT Facts</h6>
        <hr id='grammi'/>
        <ul id='ulu' style={{color:'#dadada'}}>
            <li  style={{display:'block'}}>Starting date: 1st November 2023
</li>
            <li  style={{display:'block'}}>Duration: 42 months</li>
            <li  style={{display:'block'}}>Coordinator: CERTH</li>
            <li  style={{display:'block'}}>Consortium: 13 partners from 7 European countries
 </li>
            <li  style={{display:'block'}}>More MANiBOT info at<a id='cord' target="_blank"  href='https://cordis.europa.eu/project/id/101120823'>CORDIS</a> </li>
    
          </ul>

        </div>
        
        <div class="col-lg-2 col-md-6 mb-4 mb-lg-0" id='marginfooter'>
          <h6 class="text-uppercase font-weight-bold " style={{fontSize:'30px'}}>Follow us</h6>
          <hr id='grammi'/>
          <ul class="list-unstyled mb-0">
          <li className="list-inline-item">
                        <a href="https://www.linkedin.com/showcase/manibot/" target="_blank" title="Linkedin" style={{color:'whitesmoke'}}>
                        <FontAwesomeIcon icon={faLinkedinIn}></FontAwesomeIcon>

                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://twitter.com/MANiBOT_project" target="_blank" title="Twitter"  style={{color:'whitesmoke'}}>
                        <FontAwesomeIcon icon={faXTwitter}></FontAwesomeIcon>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://www.youtube.com/channel/UCjL5csOsZQKrGCYBTUXXCKQ" target="_blank" title="Youtube"  style={{color:'whitesmoke'}}>
                        <FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon>
                        </a>
                      </li>
          </ul>
          
        </div>
        <div class="col-lg-4 col-md-6 mb-lg-0" id='marginfooter' style={{marginTop:'-11px'}}>
          <img src={eu} style={{width:'60px'}}></img>
          <hr id='grammi' style={{ top:'-5px'}}/>
          <p style={{color:'#dadada', fontSize:'1em'}}>This project has received funding from the European Union’s Horizon Europe programme under Grant Agreement No 101120823.</p>
     
          <hr id='grammi'/>
          
        </div>
      </div>
    </div>
    <div class="bg-light py-4">
      <div class="container text-center" style={{padding:'0px'}}>
      </div>
    </div>

    </footer>
  );
}

export default DarkFooter;


/*<a id='a'href="https://www.w3schools.com" style={{color:'#dadada',fontSize:'14px',cursor:'pointer'}}>Privacy policy</a>*/