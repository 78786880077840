import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';
import {Helmet} from "react-helmet";
import image1 from '../assets/img/Facilities.jpg'
import image2 from '../assets/img/Shelves_2.jpg'


const Blog3 = () => {



  return (
    <>   
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#07353d'}} className="post-title pt-3">
                  How MANiBOT Innovations Will Transform Shelf Replenishment Workflow

                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
            20/03/2025
					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                
    
                <p style={{textAlign:'justify'}}>
                In the fast-paced world of retail, efficient shelf replenishment will be crucial to maintaining customer satisfaction and ensuring the smooth operation of stores. As one of the end users of the bi-manual robotic technology, we at Masoutis are thrilled to share our aspirations on how the MANiBOT innovative project will revolutionize our shelf replenishment workflow, making it both more efficient and accurate as well!              </p> 
              <p style={{textAlign:'justify'}}>Traditionally, shelf replenishment has been a labor-intensive process, often requiring significant human resources to manually check stock levels, locate items in the warehouse, and restock shelves. This method is not only time-consuming but also prone to errors, leading to out-of-stock shelf situations or overstocking in the warehouse, both of which negatively impact customer satisfaction and the workflow.      </p>
              <p style={{textAlign:'justify'}}>  The MANiBOT project will introduce several cutting-edge technologies designed to address the above challenges. More specifically, MANiBOT robots will be equipped with advanced sensors and AI algorithms that will continuously monitor the shelf stock levels in real-time. This automation will significantly reduce the time employees spend manually checking stock, allowing for a more streamlined and efficient replenishment process.           </p>
              <div class="outer"> <figure class="img1 embed news"> <img
                  src={image1}
                  style={{borderRadius:'10px',width:'600px'}}
                  alt="about us"
                  className="img-fluid"
                />
             
                </figure></div><br/>
              
              <p style={{textAlign:'justify'}}>Moreover, because of being equipped with bi-manual robotic arms, MANiBOT robots will handle products with precision, ensuring accurate shelf placement without damage. This level of precision will reduce the risk of product damage and ensure that items will be presented neatly, resulting in improving the customer shopping experience. Additionally, the robots’ consistent and precise movements will ensure that replenishment tasks are carried out to the same high standard every time. They will place the products in the correct quantities and positions, enhancing inventory accuracy and keeping the shelves filled with properly placed products. </p>  
              <p style={{textAlign:'justify'}}>Beyond efficiency and accuracy, MANiBOT robots will also bring significant benefits to employees. By automating physically demanding tasks, they will reduce the need for workers to lift or transport heavy products, minimizing physical strain and improving workplace safety. This will also lower the risk of accidents related to shelf replenishment, such as falling products or injuries. As a result, employees can focus on more value-added tasks, such as customer service or inventory management, leading to increased productivity. Furthermore, by eliminating repetitive and physically exhausting duties, the overall working conditions will improve, reducing employee fatigue.</p>
              <div class="outer"> <figure class="img1 embed news"> <img
                  src={image2}
                  style={{borderRadius:'10px',width:'500px'}}
                  alt="about us"
                  className="img-fluid"
                />
            
                </figure></div>
                <br/>
              <p style={{textAlign:'justify'}}>To sum up, the integration of MANiBOT innovations into our shelf replenishment workflow will represent a significant leap forward in retail operations. By automating shelf monitoring and enhancing the consistency of quality control, MANiBOT will improve both the efficiency and accuracy of the replenishment process. This transformation will not only optimize operational efficiency but also elevate the overall shopping experience for our customers!
              </p>     
                <p style={{textAlign:'justify'}}>Stay tuned for more updates on how MANiBOT will revolutionize various aspects of our operations by setting a new standard in the shelf replenishment process!
              </p>
              <p style={{textAlign:'justify'}}> Panagiotis Kalaidopoulos and Eleftheria Matta, <a href='https://www.masoutis.gr/'>Masoutis</a></p>
             
             
              
              
              </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
    </>
  )
}

export default Blog3